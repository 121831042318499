/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Mobile detection
        var isMobile = false; //initiate as false
        if (/android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent)) {
          isMobile = true;
        }

        // Navigation
        $('body:not(.search):not(.error404) .nav-primary > .search-menu-item').click(function() {
          $('.nav-primary > .search-menu-item').toggleClass('active');
          if ( $('.filter-prompt-visible').length ) {
            $('h2.filter-prompt').slideToggle();
          } else if ( $('nav.nav-secondary > .nav').length ) {
            $('nav.nav-secondary > .nav.visible').slideToggle();
          }
          $('form.searchform').slideToggle();
        });

        if (isMobile) {

        // Mobile Navigation
        $('button.hamburger').click(function() {
          $('.mobile-nav-wrap').toggleClass('open');
          $('body').toggleClass('noscroll');

          if ( !($('.mobile-nav-wrap').hasClass('open')) ) {
            $(this).removeClass('open');
            $('nav.nav-primary').hide();
            $('.secondary-header').hide();
          } else {
            $(this).addClass('open');
            $('nav.nav-primary').show();
            $('.secondary-header').show();
          }

        });

        // Navigation

        if ( !($('button.hamburger.burger').length) ) {
          var selectedCategory = $('nav.nav-primary').find('.active').children().text();
          $('.mobile-nav-header button.hamburger').empty().append(selectedCategory);

          $(window).scroll(function () {
              var scroll = $(window).scrollTop();
              if( scroll > 87 ) {
                $('.mobile-nav-header').addClass('fixed');
              } else {
                $('.mobile-nav-header').removeClass('fixed');
              }
          });
        }

        var selectedSubCategory = $('nav.nav-secondary').find('.active').children().text();
        $('.mobile-nav-header .selected-sub-category').append(selectedSubCategory);

        // Mobile Navigation Filtering

          // hide clear button in mobile nav footer
          $('a.clear').hide();

          // remove href and add data attributes
          var primaryNavLinks = $('.nav-primary .nav li a');
          var secondaryNavLinks = $('.nav-secondary .nav li a');
          var mainCat = '';
          var subCat = '';

          $.each([primaryNavLinks, secondaryNavLinks], function() {
            $.each(this, function() {
              var thisUrl = $(this).attr('href');
              $(this).attr('data-url', thisUrl).attr('href', 'javascript:void(0)');
              if($(this).closest('.nav-primary').length>0) {
                $(this).addClass('filter-btn');
              } else {
                $(this).addClass('filter-btn-sub');
              }
            });
          });

          // remove active class from list items
          var primaryNav = $('.nav-primary .nav li');
          var secondaryNav = $('.nav-secondary .nav li');

          $.each(primaryNav, function() {
            if ( $(this).hasClass('active') ) {
              $(this).removeClass('active');
              var thisLink = $(this).find('a');
              thisLink.addClass('active');
              mainCat = thisLink.attr('data-url');
            }
          });

          $.each(secondaryNav, function() {
            if ( $(this).hasClass('active') ) {
              $(this).removeClass('active');
              var thisLink = $(this).find('a');
              thisLink.addClass('active');
              $('.secondary-header').addClass('open');
              subCat = thisLink.attr('data-url');
            }
          });

          $('.filter-btn').on('click', function() {

            // swap return button for clear button
            $('a.return').hide();
            $('a.clear').show();

            // hide filter prompt
            $('h2.filter-prompt').hide();

            // hide search form
            $('form.searchform').hide();
            $('.mobile-nav-footer > .search-menu-item').removeClass('active');

            // clear submenu selection
            secondaryNavLinks.removeClass('active');
            subCat = '';

            primaryNavLinks.removeClass('active');
            $(this).addClass('active');

            if ( mainCat === $(this).attr('data-url') ) {
              mainCat = '';
              $(this).removeClass('active');

              // hide submenu
              $('nav.nav-secondary .nav.visible').removeClass('visible');
              $('.secondary-header').removeClass('open');

              // show filter prompt
              $('h2.filter-prompt').show();

            } else {
              mainCat = $(this).attr('data-url');

              // show submenu
              $('nav.nav-secondary .nav.visible').removeClass('visible');
              var secondClass = $(this).parent('li').attr('class').split(' ')[1];
              var subMenuId = '#' + secondClass + '-menu';
              $(subMenuId).addClass('visible');
              $('.secondary-header').addClass('open');
            }
          });

          $('.filter-btn-sub').on('click', function() {
            // swap return button for clear button
            $('a.return').hide();
            $('a.clear').show();

            // hide search form
            $('form.searchform').hide();
            $('.mobile-nav-footer > .search-menu-item').removeClass('active');

            secondaryNavLinks.removeClass('active');
            $(this).addClass('active');

            if ( subCat === $(this).attr('data-url') ) {
              subCat = '';
              $(this).removeClass('active');
            } else {
              subCat = $(this).attr('data-url');
            }
          });

          $('a.apply').click(function() {
            var filterUrl = window.location.href;

            if (subCat) {
              filterUrl = subCat;
            } else if (mainCat === '') {
              filterUrl = '/';
            } else if (mainCat) {
              filterUrl = mainCat;
            }

            window.location.href = filterUrl;
          });

          $('a.return').click(function() {
            $('body').toggleClass('noscroll');
            $('.mobile-nav-wrap').removeClass('open');
            $('nav.nav-primary').hide();
            $('.secondary-header').hide();
          });

          $('a.clear').click(function() {

            primaryNavLinks.removeClass('active');
            secondaryNavLinks.removeClass('active');

            $('nav.nav-secondary .nav.visible').removeClass('visible');
            $('.secondary-header').removeClass('open');

            if (subCat) {
              subCat = '';
            }

            if (mainCat) {
              mainCat = '';
            }

            $('h2.filter-prompt').show();
            $(this).hide();
            $('form.searchform').hide();
            $('a.return').show();
          });

          // Search button in mobile nav footer
          $('.mobile-nav-footer > .search-menu-item').click(function() {

            primaryNavLinks.removeClass('active');
            secondaryNavLinks.removeClass('active');

            $('nav.nav-secondary .nav.visible').removeClass('visible');
            $('.secondary-header').removeClass('open');

            if (subCat) {
              subCat = '';
            }

            if (mainCat) {
              mainCat = '';
            }

            $('h2.filter-prompt').hide();
            $('nav.nav-secondary .nav.visible').removeClass('visible');

            if ( !($(this).hasClass('active')) ) {
              $('form.searchform').show();
              $(this).addClass('active');
              $('form.searchform input.search-field').focus();
            } else {
              $('form.searchform').hide();
              $(this).removeClass('active');
              $('h2.filter-prompt').show();
            }

          });
        }

        // Animate editors choice buttons
        var ecButtons = $('.ec-button');

        if ( (isMobile === false) && !(/ipad/i.test(navigator.userAgent)) ) {
          $.each( ecButtons, function() {

            var ecButton = $(this);
            var card = $(this).closest('.card-header');

            $(card).mouseenter(function() {
              $(ecButton).velocity('stop').velocity({
                width: 163,
                paddingLeft: 8,
                paddingRight: 8
                });
            }).mouseleave(function() {
              $(ecButton).velocity('stop').velocity({
                width: 0,
                paddingLeft: 0,
                paddingRight: 0
                });
            });

          });

        } else {

          $.each( ecButtons, function() {

            var ecButton = $(this);

            $(ecButton).css({
              'width': 'auto',
              'padding': '0 8px'
            });

          });

        }

        // Replace social sharing icons on hover

        if (isMobile === false) {

          var addToAnyIcons = $('.addtoany_list > a');

          $.each( addToAnyIcons, function() {

            $(this)
                .mouseover(function() {
                    var src = $(this).find('img').attr('src').match(/[^\.]+/) + '-purple.png';
                    $(this).find('img').attr('src', src);
                })
                .mouseout(function() {
                    var src = $(this).find('img').attr('src').replace('-purple.png', '.png');
                    $(this).find('img').attr('src', src);
                });

          });

        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
